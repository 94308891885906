import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  useParams,
  useLocation,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import apollo from './lib/apollo';
import LoadingPage from './pages/LoadingPage';
import PageActivityTracker from './components/PageActivityTracker';
import { AuthProvider, RequireAuth } from './contexts/authenticate';

const trackingId = 'G-7SE3CL0JCV';

const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const WelcomePage = lazy(() => import('./pages/WelcomePage'));
const DocumentPage = lazy(() => import('./pages/DocumentPage'));
const ProposalPage = lazy(() => import('./pages/ProposalPage'));
const FilePage = lazy(() => import('./pages/FilePage'));
const ComparePage = lazy(() => import('./pages/ComparePage'));
const Builder2Page = lazy(() => import('./pages/Builder2Page'));
const Builder3Page = lazy(() => import('./pages/Builder3Page'));
const ReferPage = lazy(() => import('./pages/ReferPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));
const SchedulePage = lazy(() => import('./pages/SchedulePage'));
const BillUploadPage = lazy(() => import('./pages/BillUploadPage'));
const RecommendationPage = lazy(() =>
  import('./pages/recommendations/RecommendationPage')
);

const BaseRecommendationPage = lazy(() =>
  import('./pages/recommendations/BaseRecommendationPage')
);
const AppointmentConfirmationPage = lazy(() =>
  import('./pages/appointments/AppointmentConfirmationPage')
);
const EmployeePage = lazy(() => import('./pages/employees/EmployeePage'));

const AuthorizationPage = lazy(() =>
  import('./pages/proposals/AuthorizationPage')
);
const ProposalSetupPage = lazy(() => import('./pages/proposals/SetupPage'));
const ProposalContractPage = lazy(() =>
  import('./pages/proposals/ContractPage')
);
const ProposalCompletedPage = lazy(() =>
  import('./pages/proposals/CompletedPage')
);
const ProposalShadeReportPage = lazy(() =>
  import('./pages/proposals/ShadeReportPage')
);
const ProductionReportPage = lazy(() =>
  import('./pages/proposals/ProductionReportPage')
);
const ProposalDebugPage = lazy(() => import('./pages/proposals/DebugPage'));
const ProposalSummaryPage = lazy(() => import('./pages/proposals/SummaryPage'));
const DesignShadeReportPage = lazy(() =>
  import('./pages/designs/ShadeReportPage')
);

const CongratulationsPage = lazy(() => import('./pages/CongratulationsPage'));

const GoodleapLoanApplicationPage = lazy(() =>
  import('./pages/financing/goodleap/GoodleapLoanApplicationPage')
);
const GoogleSolarPage = lazy(() => import('./pages/designs/GoogleSolarPage'));

const PalmettoApplicationPage = lazy(() =>
  import('./pages/financing/palmetto/PalmettoApplicationPage')
);
const RecommendationsPage = lazy(() => import('./pages/RecommendationsPage'));
const ShowRecommendationsPage = lazy(() =>
  import('./pages/RecommendationsPage/ShowRecommendationsPage')
);
const EditDesignPage = lazy(() => import('./pages/designs/EditDesignPage'));
const AdminProposalPreparePage = lazy(() =>
  import('./pages/admin/proposals/PreparePage')
);
const AdminNewProposalPage = lazy(() =>
  import('./pages/admin/proposals/NewProposalPage')
);
const AdminShowProposalPage = lazy(() =>
  import('./pages/admin/proposals/ShowProposalPage/index')
);

function GoogleTracker({ children }) {
  // useTracking('G-7SE3CL0JCV');
  const location = useLocation();
  useEffect(() => {
    if (!window.gtag) return;
    if (!trackingId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
      );
      return;
    }

    window.gtag('config', trackingId, { page_path: location.pathname });
  }, [location]);
  return children;
}

function RedirectReferral() {
  const { rid } = useParams();
  const location = useLocation();
  return <Navigate replace to={`/r/${rid}/schedule${location.search || ''}`} />;
}
// basename={gon.base_path}
export default function App() {
  return (
    <ApolloProvider client={apollo}>
      <Router>
        <GoogleTracker>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route element={<AuthProvider />}>
                {window?.gon?.user && (
                  <Route
                    path="/p/:uid/admin/recommendations/:recommendationProfileId"
                    element={<RecommendationsPage />}
                  />
                )}
                {window?.gon?.user && (
                  <Route
                    path="/p/:uid/admin/recommendations"
                    element={<RecommendationsPage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path="/p/:uid/admin/proposals3/new"
                    element={<Builder3Page />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path="/p/:uid/admin/proposals3/:quoteId"
                    element={<Builder3Page />}
                  />
                )}

                {window.gon?.user && (
                  <Route
                    path="/p/:uid/admin/proposals/new"
                    element={<Builder2Page />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path="/p/:uid/admin/proposals/:quoteId"
                    element={<Builder2Page />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/p/:uid/admin/proposals/:proposalId/debug`}
                    element={<ProposalDebugPage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/p/:uid/admin/proposals/:proposalId/production-report`}
                    element={<ProductionReportPage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/admin/designs/:designId`}
                    element={<EditDesignPage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/admin/p/:uid/prepare`}
                    element={<AdminProposalPreparePage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/admin/p/:uid/proposals/new`}
                    element={<AdminNewProposalPage />}
                  />
                )}
                {window.gon?.user && (
                  <Route
                    path={`/admin/p/:uid/proposals/:proposalId`}
                    element={<AdminShowProposalPage />}
                  />
                )}

                <Route path="/e/:eid" element={<EmployeePage />} />
                <Route path="/r/:rid" element={<RedirectReferral />} />

                <Route path="/c/:cid/schedule" element={<SchedulePage />} />
                <Route path="/p/:uid/schedule" element={<SchedulePage />} />
                <Route path="/c/:cid/calendar" element={<CalendarPage />} />
                <Route
                  path="/p/:uid/recommendations"
                  element={<RecommendationPage />}
                />
                <Route
                  path="/p/recommendations"
                  element={<BaseRecommendationPage />}
                />
                <Route
                  path="/p/:uid/schedule2"
                  element={<SchedulePage version={2} />}
                />
                <Route path="/e/:eid/schedule" element={<SchedulePage />} />
                <Route
                  path="/r/:rid/schedule"
                  element={<SchedulePage isReferral={true} />}
                />
                <Route
                  path="/r/:rid/schedule2"
                  element={<SchedulePage isReferral={true} version={2} />}
                />
                <Route path="/schedule" element={<SchedulePage />} />
                <Route
                  path="/p/:uid/appointments/:appointmentId"
                  element={<AppointmentConfirmationPage />}
                />

                <Route path="/p/:uid/welcome" element={<WelcomePage />} />
                <Route
                  path="/p/:uid/congratulations"
                  element={
                    <RequireAuth>
                      <CongratulationsPage />
                    </RequireAuth>
                  }
                />

                <Route path="/p/:uid/refer" element={<ReferPage />} />
                <Route
                  path="/p/:uid/compare"
                  element={
                    <PageActivityTracker type="Activity::ViewedComparison">
                      <ComparePage />
                    </PageActivityTracker>
                  }
                />
                <Route path="/p/:uid/bill" element={<BillUploadPage />} />

                <Route
                  path={`/p/:uid/proposals/:quoteId/apply`}
                  element={
                    <RequireAuth>
                      <GoodleapLoanApplicationPage />
                    </RequireAuth>
                  }
                />

                <Route
                  path={`/p/:uid/proposals/:quoteId/palmetto/apply`}
                  element={
                    <RequireAuth>
                      <PalmettoApplicationPage />
                    </RequireAuth>
                  }
                />

                <Route
                  path={`/p/:uid/proposals/:proposalId/setup`}
                  element={
                    <RequireAuth>
                      <ProposalSetupPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path={`/p/:uid/proposals/:proposalId/contract`}
                  element={
                    <RequireAuth>
                      <ProposalContractPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path={`/p/:uid/proposals/:proposalId/completed`}
                  element={<ProposalCompletedPage />}
                />
                <Route
                  path={`/p/:uid/proposals/:proposalId/shade-report`}
                  element={<ProposalShadeReportPage />}
                />
                <Route
                  path={`/p/:uid/proposals/:proposalId/summary`}
                  element={<ProposalSummaryPage />}
                />

                <Route
                  path={`/p/:uid/proposals/:proposalId`}
                  element={
                    <PageActivityTracker type="Activity::ViewedQuote">
                      <ProposalPage />
                    </PageActivityTracker>
                  }
                />
                <Route
                  path={`/designs/:designId/google`}
                  element={<GoogleSolarPage />}
                />
                <Route
                  path={`/designs/:designId`}
                  element={<DesignShadeReportPage />}
                />

                <Route
                  path={`/p/:uid/documents/:documentId`}
                  element={
                    <PageActivityTracker type="Activity::ViewedArtifact">
                      <DocumentPage />
                    </PageActivityTracker>
                  }
                />
                <Route path={`/p/:uid/files/:fileId`} element={<FilePage />} />

                <Route
                  path={`/p/:uid/authenticate`}
                  element={<AuthorizationPage />}
                />
                <Route
                  path="/p/:uid"
                  element={
                    <PageActivityTracker type="Activity::ViewedPortal">
                      <DashboardPage />
                    </PageActivityTracker>
                  }
                />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </Suspense>
        </GoogleTracker>
      </Router>
    </ApolloProvider>
  );
}
