import { tenantPrimaryColor } from 'shared/lib/tenant';

// Function to darken a hex color by a percentage
const darkenColor = (hex, percent) => {
  // Remove # if present
  hex = hex.replace('#', '');

  // Convert to RGB
  let r = parseInt(hex.substr(0, 2), 16);
  let g = parseInt(hex.substr(2, 2), 16);
  let b = parseInt(hex.substr(4, 2), 16);

  // Darken each component
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Convert back to hex
  return (
    '#' +
    ((r < 16 ? '0' : '') + r.toString(16)) +
    ((g < 16 ? '0' : '') + g.toString(16)) +
    ((b < 16 ? '0' : '') + b.toString(16))
  );
};

const root = document.documentElement;
root.style.setProperty('--tenant-primary-color', tenantPrimaryColor);
// Add darkened version (20% darker)
root.style.setProperty(
  '--tenant-primary-color-dark',
  darkenColor(tenantPrimaryColor, 20)
);
console.log('Tenant styles injected', tenantPrimaryColor);
